import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useMedia } from '../../../hooks/useMedia'
import NavBar from '../../atoms/NavBar'
import { HStack, Spinner } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import { Box, Heading, Container, Text } from '@chakra-ui/react'
import Card from '../../atoms/Card'
// import UppyWidget from '../../organisms/UppyWidget'
import FooterBase from '../../molecules/FooterBase'
import { useVideoDurationCalc } from '../../../hooks/useVideoDurationCalc'
import { maxMediaCount, maxMediaLength } from '../../../../../client/config.client'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
// import Uploader from '../../organisms/Uppy'
import UploaderCreator from '../../organisms/Uppy/src/UploaderCreator'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
const allowedFileTypes = ['video/*', 'image/*', '.jpg', '.jpeg', '.png', '.webp', '.gif', '.tiff', '.heic', 'audio/*'] //['image/*', 'video/*', 'video/3gpp', 'video/x-matroska', 'video/x-flv']

const Controls = () => {
	const { uuid } = useParams()
	const navigate = useNavigate()

	return (
		<NavBar>
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(`/event/${uuid}#addMedia`)} />
			</HStack>
		</NavBar>
	)
}

const UploadMedia = () => {
	useScrollTopOnMount()
	const { uuid } = useParams()
	const { event } = useEventContext()
	const media = useMedia()
	const navigate = useNavigate()

	/** Calculate Total Video Length in Seconds */
	const { durationSec } = useVideoDurationCalc(media, event.themeTemplateId)

	/** Media Limits - prevent upload */
	let videoLengthInSec = media.length > 0 ? durationSec : 0
	const overLimit = media?.length >= maxMediaCount || videoLengthInSec >= maxMediaLength

	const handleBack = () => navigate(`/event/${uuid}#addMedia`)

	if (overLimit) {
		// Sorry not sorry
		handleBack()
	}

	let myDevice = []
	if (isDesktop) {
		myDevice = {
			name: 'Desktop/Laptop',
			icon: '🖥',
			option: { name: 'Mobile Device', icon: '📱' },
		}
	} else if (isAndroid) {
		myDevice = {
			name: 'Android Device',
			icon: '📱',
			option: { name: 'Desktop/Laptop', icon: '🖥' },
		}
	} else if (isIOS) {
		myDevice = {
			name: 'iPhone',
			icon: '📱',
			option: { name: 'Desktop/Laptop', icon: '🖥' },
		}
	} else {
		myDevice = { name: os, option: { name: 'Desktop/Laptop', icon: '🖥' } }
	}

	if (event.id) {
		return (
			<>
				<Controls />

				<Container maxW="container.md" py="1rem" variant="main">
					<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
						<Heading as="h2" size="xl" variant="hero">
							Upload Options
						</Heading>

						<Text>
							You can also upload using a <strong>{myDevice.option.name}</strong> {myDevice.option.icon}
						</Text>
					</Box>

					<Card
						alignItems="center"
						variant="main"
						mb="2rem"
						textAlign="center"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<UploaderCreator
							event={event}
							handleClose={handleBack}
							allowedFileTypes={allowedFileTypes}
							maxNumberOfFiles={200}
						/>
					</Card>

					<FooterBase />
				</Container>
			</>
		)
	}

	return <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
}

export default UploadMedia
